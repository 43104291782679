import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import HistoryIcon from '@mui/icons-material/History';
import Button from '@mui/material/Button';
import HtmlTooltip from '../components/HtmlTooltip';
import CellWithPopover from '../components/Popover';
import SlidingPanel from '../components/SlidingPanel';
import { apiClient } from '../apiClient';

// Types
export interface AuditEvent {
  type: string;
  version: number;
  uid: string;
  data: {
    [key: string]: any;
  };
  created_ts: string;
  org_uid?: string;
  schedule_uid?: string;
  schedule_version_uid?: string;
  time_series_uid?: string;
  time_series_version_uid?: string;
  user_uid?: string;
  api_client_id?: string;
}

export interface LogQueryParams {
  schedule_uid?: string | null;
  schedule_version_uid?: string | null;
  limit?: number | null;
  after?: string | null;
  order?: 'oldest-first' | 'newest-first' | null;
  // Add any additional query parameters you might need
}

// Color mapping for event types
const eventTypeColors: Record<string, string> = {
  'Schedule created': 'success',
  // Add more event types as needed
};

// Helper to get an appropriate color for any event type
const getEventTypeColor = (type: string): 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' => {
  return (eventTypeColors[type] as any) || 'default';
};

const formatTimestamp = (timestamp: string) => {
  return dayjs(timestamp).format('YYYY-MM-DD HH:mm:ss');
};

const CustomNoRowsOverlay = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        gap: 1,
      }}
    >
      <HistoryIcon sx={{ fontSize: 40 }} />
      <Typography>No audit events found.</Typography>
    </Box>
  );
};

interface AuditLogGridProps {
  // Initial query parameters - all optional with defaults
  initialParams?: LogQueryParams;
  // Optional filter values
  dateRange?: [any, any];
  eventType?: string;
  // Callbacks
  onError?: (error: string) => void;
  // UI Customization
  hideScheduleUidColumn?: boolean; // Hide the Schedule UID column when viewing a specific schedule
  minHeight?: string | number;
}

// Create a ref-forwarding component
const AuditLogGrid = forwardRef<
  { refreshData: (params?: LogQueryParams) => void },
  AuditLogGridProps
>(({
  initialParams = { order: 'newest-first', limit: 100 },
  dateRange = [null, null],
  eventType = '',
  onError,
  hideScheduleUidColumn = false,
  minHeight = 320,
}, ref) => {
  const [auditEvents, setAuditEvents] = useState<AuditEvent[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [filteredRows, setFilteredRows] = useState<any[]>([]);

  // State for the sliding panel
  const [panelOpen, setPanelOpen] = useState<boolean>(false);
  const [selectedEventData, setSelectedEventData] = useState<any>(null);
  const [selectedEventType, setSelectedEventType] = useState<string>('');

  const handleViewDetails = (data: any, eventType: string) => {
    setSelectedEventData(data);
    setSelectedEventType(eventType);
    setPanelOpen(true);
  };

  const handleClosePanel = () => {
    setPanelOpen(false);
  };

  // Construct columns - now we can conditionally include/exclude columns
  const getColumns = (): GridColDef[] => {
    const baseColumns: GridColDef[] = [
      {
        field: 'created_ts',
        headerName: 'Timestamp',
        flex: 1,
        minWidth: 180,
        valueFormatter: (params) => formatTimestamp(params as string),
      },
      {
        field: 'type',
        headerName: 'Event Type',
        flex: 1,
        minWidth: 200,
        renderCell: (params) => (
          <Chip
            variant="outlined"
            label={params.value as string}
            color={getEventTypeColor(params.value as string)}
            size="small"
          />
        ),
      },
      {
        field: 'user_uid',
        headerName: 'User',
        flex: 1,
        minWidth: 100,
        renderCell: (params) => {
          if (!params.value) return <span>-</span>;
          return <CellWithPopover value={params.value} displayValue={`...${params.value.slice(-8)}`} />;
        },
      },
      {
        field: 'api_client_id',
        headerName: 'API Client',
        flex: 1,
        minWidth: 120,
        renderCell: (params) => {
          if (!params.value) return <span>-</span>;
          return <span>{params.value}</span>;
        },
      },
      {
        field: 'data',
        headerName: 'Event Data',
        flex: 1,
        minWidth: 200,
        renderCell: (params) => {
          // Calculate preview information
          const data = params.row.data;
          if (!data) return <Button variant="text" size="small" disabled>No Data</Button>;

          const attributeCount = Object.keys(data).length;
          const previewKeys = Object.keys(data).slice(0, 3);
          const hasMoreFields = attributeCount > 3;

          return (
            <HtmlTooltip
              title={
                <Box sx={{ maxHeight: 200, overflow: 'auto', p: 1, maxWidth: 300 }}>
                  <Box sx={{ mb: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="subtitle2">Event Data Preview</Typography>
                    <Chip 
                      label={`${attributeCount} field${attributeCount !== 1 ? 's' : ''}`} 
                      size="small" 
                      variant="outlined"
                      color="primary" 
                      sx={{ fontSize: '0.7rem' }}
                    />
                  </Box>

                  {previewKeys.map((key) => {
                    const value = data[key];
                    const displayValue = typeof value === 'object' 
                      ? JSON.stringify(value).substring(0, 30) + '...' 
                      : String(value).substring(0, 50) + (String(value).length > 50 ? '...' : '');

                    return (
                      <Box key={key} sx={{ mb: 0.5 }}>
                        <Typography variant="caption" sx={{ fontWeight: 'bold', color: 'text.secondary' }}>
                          {key}:
                        </Typography>
                        <Typography variant="body2" sx={{ ml: 1, wordBreak: 'break-word' }}>
                          {displayValue}
                        </Typography>
                      </Box>
                    );
                  })}

                  {hasMoreFields && (
                    <Typography
                      variant="caption"
                      sx={{ display: 'block', mt: 1, color: 'text.secondary', fontStyle: 'italic' }}
                    >
                      Click "View Details" to see all {attributeCount} fields...
                    </Typography>
                  )}
                </Box>
              }
              placement="left"
            >
              <Button
                variant="text"
                size="small"
                onClick={() => handleViewDetails(params.row.data, params.row.type)}
              >
                View Details
              </Button>
            </HtmlTooltip>
          );
        },
      },
    ];

    // Conditionally add the schedule_uid column if it's not hidden
    if (!hideScheduleUidColumn) {
      const scheduleUidColumn = {
        field: 'schedule_uid',
        headerName: 'Schedule UID',
        flex: 1,
        minWidth: 100,
        renderCell: (params: any) => {
          if (!params.value) return <span>-</span>;
          return (
            <Link to={`/schedule-management/debugger/${params.value}`} style={{ textDecoration: 'none', color: '#1976d2' }}>
              <CellWithPopover value={params.value} displayValue={`${params.value}`} />
            </Link>
          );
        },
      };
      
      // Insert after the event type column (index 1)
      baseColumns.splice(2, 0, scheduleUidColumn);
    }

    return baseColumns;
  };

  const fetchAuditEvents = async (queryParams: LogQueryParams = {}) => {
    setLoading(true);

    // Merge with initialParams, with passed queryParams taking precedence
    const mergedParams: LogQueryParams = {
      ...initialParams,
      ...queryParams
    };

    // Build query string
    const queryString = Object.entries(mergedParams)
      .filter(([_, value]) => value !== null && value !== '')
      .map(([key, value]) => `${key}=${encodeURIComponent(String(value))}`)
      .join('&');

    try {
      const response = await apiClient(`audit-logs${queryString ? `?${queryString}` : ''}`);

      if (!response.ok) {
        throw new Error('Failed to fetch audit events');
      }

      const data = await response.json();
      setAuditEvents(data);

      // Transform data for the DataGrid
      const mappedRows = data.map((event: AuditEvent, index: number) => ({
        id: index,
        ...event,
      }));

      setRows(mappedRows);
      setFilteredRows(mappedRows);
      setError(null);
    } catch (error) {
      console.error(error);
      const errorMessage = 'Failed to load audit events';
      setError(errorMessage);
      if (onError) onError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAuditEvents();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [dateRange, eventType, rows]);

  const applyFilters = () => {
    let filtered = [...rows];

    // Filter by date range
    const [startDate, endDate] = dateRange;
    if (startDate && endDate) {
      filtered = filtered.filter((row) => {
        const eventDate = dayjs(row.created_ts);
        return (
          (eventDate.isAfter(startDate, 'day') || eventDate.isSame(startDate, 'day')) &&
          (eventDate.isBefore(endDate, 'day') || eventDate.isSame(endDate, 'day'))
        );
      });
    }

    // Filter by event type
    if (eventType) {
      filtered = filtered.filter((row) => row.type === eventType);
    }

    setFilteredRows(filtered);
  };

  // Method to reload data - can be called from parent component
  const refreshData = (params: LogQueryParams = {}) => {
    fetchAuditEvents(params);
  };
  
  // Expose methods to parent component through the ref
  useImperativeHandle(ref, () => ({
    refreshData
  }));

  return (
    <>
      {error && (
        <Box sx={{ mb: 2, p: 2, bgcolor: '#ffebee', borderRadius: 1 }}>
          <Typography color="error">{error}</Typography>
        </Box>
      )}

      <Box style={{ display: 'flex', flexDirection: 'column', minHeight: loading ? minHeight : 'auto' }}>
        <DataGrid
          rows={filteredRows}
          columns={getColumns()}
          loading={loading}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          slotProps={{
            loadingOverlay: {
              variant: 'skeleton',
              noRowsVariant: 'skeleton',
            },
          }}
          density="compact"
          pageSizeOptions={[10, 20, 50, 100]}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
                page: 0
              }
            },
            sorting: {
              sortModel: [{ field: 'created_ts', sort: 'desc' }],
            },
          }}
          pagination
        />
      </Box>

      {/* Sliding Panel Component */}
      <SlidingPanel
        open={panelOpen}
        onClose={handleClosePanel}
        title={`Event Details: ${selectedEventType}`}
        data={selectedEventData}
      />
    </>
  );
});

export default AuditLogGrid;

import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';

interface DeleteApiKeyModalProps {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
  apiKeyName: string | null;
}

const DeleteApiKeyDialog: React.FC<DeleteApiKeyModalProps> = ({ open, onClose, onDelete, apiKeyName }) => {
  if(!apiKeyName){
    open = false;
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm Deletion</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to delete the API key "{apiKeyName}"? This action cannot be undone.</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Cancel</Button>
        <Button onClick={onDelete} color="error" variant="contained">Delete</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteApiKeyDialog;

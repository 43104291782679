import { useState, useRef } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import Page from '../../components/Page';
import AuditLogGrid, { LogQueryParams } from '../../components/AuditLogGrid';

// Define the type for our grid ref
type AuditLogGridRef = {
  refreshData: (params?: LogQueryParams) => void;
};

const AuditLogPage = () => {
  const [dateRange, setDateRange] = useState<[any, any]>([null, null]);
  const [scheduleUid, setScheduleUid] = useState<string>('');
  const [eventType, setEventType] = useState<string>('');
  const [order, setOrder] = useState<'oldest-first' | 'newest-first'>('newest-first');
  const [limit, setLimit] = useState<number>(100);
  const [error, setError] = useState<string | null>(null);

  // Reference to the grid component to call methods on it
  const gridRef = useRef<AuditLogGridRef>(null);

  // Get unique event types for the filter dropdown - we'll need to populate this from the backend or use a predefined list
  const uniqueEventTypes = [
    'Schedule created',
    'schedule_updated',
    'schedule_deleted',
    'schedule_version_created',
    'schedule_version_updated',
    'schedule_version_validated',
    'schedule_version_submitted',
    'schedule_version_accepted',
    'schedule_version_rejected',
    'time_series_created',
    'time_series_updated',
    'time_series_deleted',
  ];

  const handleClearFilters = () => {
    setDateRange([null, null]);
    setEventType('');
  };

  const handleSearch = () => {
    const queryParams: LogQueryParams = {
      order,
      limit,
    };

    // Add optional filters if they exist
    if (scheduleUid) queryParams.schedule_uid = scheduleUid;

    // Call the refresh method on the grid component
    if (gridRef.current) {
      gridRef.current.refreshData(queryParams);
    }
  };

  const handleError = (errorMessage: string) => {
    setError(errorMessage);
  };

  return (
    <Page>
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Event Log
      </Typography>

      <Box sx={{ mb: 3 }}>
        <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
          <TextField
            label="Schedule UID"
            value={scheduleUid}
            onChange={(e) => setScheduleUid(e.target.value)}
            size="small"
            sx={{ width: 240 }}
          />

          <FormControl size="small" sx={{ width: 180 }}>
            <InputLabel id="order-select-label">Order</InputLabel>
            <Select
              labelId="order-select-label"
              value={order}
              label="Order"
              onChange={(e) => setOrder(e.target.value as 'oldest-first' | 'newest-first')}
            >
              <MenuItem value="newest-first">Newest First</MenuItem>
              <MenuItem value="oldest-first">Oldest First</MenuItem>
            </Select>
          </FormControl>

          <FormControl size="small" sx={{ width: 120 }}>
            <InputLabel id="limit-select-label">Limit</InputLabel>
            <Select
              labelId="limit-select-label"
              value={limit}
              label="Limit"
              onChange={(e) => setLimit(Number(e.target.value))}
            >
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={500}>500</MenuItem>
              <MenuItem value={1000}>1000</MenuItem>
            </Select>
          </FormControl>

          <Button
            variant="contained"
            color="primary"
            onClick={handleSearch}
          >
            Search
          </Button>
        </Stack>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
            <DatePicker
              label="Start Date"
              value={dateRange[0]}
              onChange={(newValue) => setDateRange([newValue, dateRange[1]])}
              slotProps={{
                textField: {
                  size: "small",
                  sx: { width: 200 }
                }
              }}
            />
            <DatePicker
              label="End Date"
              value={dateRange[1]}
              onChange={(newValue) => setDateRange([dateRange[0], newValue])}
              slotProps={{
                textField: {
                  size: "small",
                  sx: { width: 200 }
                }
              }}
              minDate={dateRange[0]}
            />

            <FormControl size="small" sx={{ width: 200 }}>
              <InputLabel id="event-type-label">Event Type</InputLabel>
              <Select
                labelId="event-type-label"
                value={eventType}
                label="Event Type"
                onChange={(e) => setEventType(e.target.value)}
              >
                <MenuItem value="">All Events</MenuItem>
                {uniqueEventTypes.map((type) => (
                  <MenuItem key={type} value={type}>{type}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button
              variant="outlined"
              size="small"
              onClick={handleClearFilters}
              disabled={!dateRange[0] && !dateRange[1] && !eventType}
            >
              Clear Filters
            </Button>
          </Stack>
        </LocalizationProvider>
      </Box>

      {error && (
        <Box sx={{ mb: 2, p: 2, bgcolor: '#ffebee', borderRadius: 1 }}>
          <Typography color="error">{error}</Typography>
        </Box>
      )}

      <AuditLogGrid
        ref={gridRef}
        initialParams={{ order, limit, schedule_uid: scheduleUid || null }}
        dateRange={dateRange}
        eventType={eventType}
        onError={handleError}
      />
    </Page>
  );
};

export default AuditLogPage;
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { LineChart } from '@mui/x-charts/LineChart';
import { styled } from '@mui/system';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import RefreshIcon from '@mui/icons-material/Refresh';

import Page from '../../components/Page';
import StatusChip from '../../components/StatusChip';
import AuditLogGrid, { LogQueryParams } from '../../components/AuditLogGrid';
import { apiClient } from '../../apiClient';

// Define the type for our grid ref
type AuditLogGridRef = {
  refreshData: (params?: LogQueryParams) => void;
};

interface ScheduleDetails {
  uid: string;
  acceptance_level?: string;
  created_ts: string;
  last_modified_ts: string;
  status?: string;
  time_series_uid: string;
  version: number;
  spec: {
    ids: {
      in_area: string;
      out_area: string;
      in_party: string;
      out_party: string;
    };
    type: string;
    data: Array<{ ts: string; amount: number }>;
  };
  versions?: Array<{
    id: string;
    label: string;
    showMark?: boolean;
    curve?: 'catmullRom' | 'linear'| 'monotoneX' | 'monotoneY'|'natural' | 'step' | 'stepBefore' | 'stepAfter';
    data: number[];
    area?: boolean;
    stackOrder?: 'ascending' | 'descending';
  }>;
}

function AreaGradient({ color, id }: { color: string; id: string }) {
  return (
    <defs>
      <linearGradient id={id} x1="50%" y1="0%" x2="50%" y2="100%">
        <stop offset="0%" stopColor={color} stopOpacity={0.5} />
        <stop offset="100%" stopColor={color} stopOpacity={0} />
      </linearGradient>
    </defs>
  );
}

const ScheduleDetailsPage = () => {
  const { scheduleId } = useParams<{ scheduleId: string }>();
  const [scheduleDetails, setScheduleDetails] = useState<ScheduleDetails[]>([]);
  const [error, setError] = useState<string | null>(null);
  const theme = useTheme();
  
  // Audit log state
  const [dateRange, setDateRange] = useState<[any, any]>([null, null]);
  const [eventType, setEventType] = useState<string>('');
  const [limit, setLimit] = useState<number>(50);
  const [auditError, setAuditError] = useState<string | null>(null);
  const auditLogRef = useRef<AuditLogGridRef>(null);
  
  // For forcing refresh on audit logs
  const [refreshKey, setRefreshKey] = useState<number>(0);

  const colorPalette = [
    theme.palette.primary.light,
    theme.palette.primary.main,
    theme.palette.primary.dark,
  ];

  useEffect(() => {
    if (scheduleId) {
      apiClient(`schedule-series/${scheduleId}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data: ScheduleDetails[]) => {
          type GroupedSchedules = {
            [key: string]: ScheduleDetails & {
              versions: Array<{
                id: string;
                label: string;
                showMark: boolean;
                curve: string;
                data: number[];
                area: boolean;
                stackOrder: string;
              }>;
              xAxis: string[];
            };
          };

          const groupedSchedules = data.reduce<GroupedSchedules>((acc, schedule, index) => {
            const { time_series_uid } = schedule;

            if (!acc[time_series_uid]) {
              acc[time_series_uid] = {
                ...schedule,
                versions: [],
                xAxis: [],
                spec: {
                  ...schedule.spec,
                  data: [],
                },
              };
            }

            // Extract x and y values
            const xValues = schedule.spec.data.map((point) => point.ts);
            const yValues = schedule.spec.data.map((point) => point.amount);

            // Add x-values if not already set
            if (acc[time_series_uid].xAxis.length === 0) {
              acc[time_series_uid].xAxis = xValues;
            }

            // Add this schedule's version as a chart series
            acc[time_series_uid].versions.push({
              id: `chart-${index}-${schedule.version}`,
              label: `Version ${schedule.version}`,
              showMark: false,
              curve: 'linear',
              data: yValues, // Only y-values
              area: true,
              stackOrder: 'descending',
            });

            // Merge data for use in other parts if needed
            acc[time_series_uid].spec.data.push(...schedule.spec.data);

            return acc;
          }, {});

          // Convert groupedSchedules object to array
          const mergedSchedules = Object.values(groupedSchedules);
          mergedSchedules.forEach((schedule) => {
            // Reverse the versions array for each schedule (didn't work with ascending on the chart)
            schedule.versions.reverse();
          });

          setScheduleDetails(mergedSchedules as ScheduleDetails[]);
        })
        .catch((err) => {
          setError(err.message);
        });
    }
  }, [scheduleId]);

  const colorMap: { [key: string]: string } = {
    production: 'hsl(120, 61%, 50%)', // Green
    consumption: 'hsl(30, 100%, 50%)', // Orange
    internal: 'hsl(200, 70%, 50%)',    // Blue
    external: 'hsl(280, 60%, 50%)',    // Purple
  };

  const SmallTextTableCell = styled(TableCell)(({ theme }) => ({
    fontSize: '0.75rem',
    padding: '2px 20px',
  }));
  
  // Get unique event types for the filter dropdown
  const uniqueEventTypes = [
    'Schedule created',
    'schedule_updated',
    'schedule_deleted',
    'schedule_version_created',
    'schedule_version_updated',
    'schedule_version_validated',
    'schedule_version_submitted',
    'schedule_version_accepted',
    'schedule_version_rejected',
  ];

  const handleClearFilters = () => {
    setDateRange([null, null]);
    setEventType('');
  };

  const handleRefresh = () => {
    if (auditLogRef.current) {
      auditLogRef.current.refreshData();
    } else {
      // If ref is not available, force a remount via key
      setRefreshKey(prev => prev + 1);
    }
  };

  const handleAuditError = (errorMessage: string) => {
    setAuditError(errorMessage);
  };

  return (
    <Page>
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Schedule Details for schedule {scheduleId}
      </Typography>
      {error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <>
          <Grid container spacing={2}>
            {scheduleDetails.map((schedule, index) => {
              const timestamps = schedule.spec.data.map((item) => item.ts);
              const latestVersion = schedule.versions?.[schedule.versions.length - 1];
              const totalAmount = (latestVersion?.data.reduce((sum, item) => sum + item, 0) || 0).toFixed(3);
              const gradientColor = colorMap[schedule.spec.type];

              return (
                <Grid item xs={12} md={6} key={schedule.uid}>
                  <Card variant="outlined">
                    <CardContent>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={2}
                        sx={{ mb: 2 }}
                      >
                        <Box sx={{ flex: 1 }}>
                          <Typography
                            variant="h4"
                            gutterBottom
                            sx={{ color: gradientColor }}
                          >
                            {schedule.spec.type.charAt(0).toUpperCase() + schedule.spec.type.slice(1)}
                          </Typography>

                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                            sx={{ mb: 1 }}
                          >
                            <Typography variant="h4" component="p">
                              {totalAmount} MW
                            </Typography>
                            <StatusChip
                              status={(() => {
                                if (schedule.status === 'accepted') {
                                  if (schedule.acceptance_level === 'accepted_preliminary') {
                                    return 'accepted (preliminary)';
                                  } else if (schedule.acceptance_level === 'accepted_final') {
                                    return 'accepted (final)';
                                  }
                                }
                                return schedule.status || 'unknown';
                              })() as 'accepted' | 'submitted' | 'pending' | 'partially_accepted' | 'rejected' | string}
                            />
                          </Stack>
                          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                            Timeseries UID: {schedule.time_series_uid}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            minWidth: '250px',
                            borderRadius: 1,
                            border: '1px solid hsla(220, 20%, 25%, 0.6)',
                            backgroundColor: 'background.paper',
                            overflow: 'hidden',
                          }}
                        >
                          <Table size="small" sx={{ fontSize: '0.75rem' }}>
                            <TableBody>
                              <TableRow>
                                <SmallTextTableCell variant="head">In Area</SmallTextTableCell>
                                <SmallTextTableCell>{schedule.spec.ids.in_area}</SmallTextTableCell>
                              </TableRow>
                              <TableRow>
                                <SmallTextTableCell variant="head">Out Area</SmallTextTableCell>
                                <SmallTextTableCell>{schedule.spec.ids.out_area}</SmallTextTableCell>
                              </TableRow>
                              <TableRow>
                                <SmallTextTableCell variant="head">In Party</SmallTextTableCell>
                                <SmallTextTableCell>{schedule.spec.ids.in_party}</SmallTextTableCell>
                              </TableRow>
                              <TableRow>
                                <SmallTextTableCell variant="head">Out Party</SmallTextTableCell>
                                <SmallTextTableCell>{schedule.spec.ids.out_party}</SmallTextTableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Box>
                      </Stack>

                      <LineChart
                        colors={(schedule.versions || []).map(
                          (_, versionIndex) => colorMap[schedule.spec.type] || colorPalette[versionIndex % colorPalette.length]
                        )}
                        xAxis={[
                          {
                            scaleType: 'point',
                            data: timestamps,
                            tickInterval: (index, i) => (i + 1) % 5 === 0,
                          },
                        ]}
                        series={schedule.versions || []}
                        height={200}
                        margin={{ left: 50, right: 20, top: 20, bottom: 20 }}
                        grid={{ horizontal: true }}
                        sx={{
                          ...(schedule.versions || []).reduce<Record<string, any>>((styles, version, versionIndex) => {
                            styles[`& .MuiAreaElement-series-${version.id}`] = {
                              fill: `url(#${version.id})`,
                            };
                            styles[`& .MuiLineElement-series-${version.id}`] = {
                              stroke: gradientColor,
                            };
                            return styles;
                          }, {}),
                        }}
                        slotProps={{
                          legend: {
                            hidden: true,
                          },
                        }}
                      >
                        <defs>
                          {(schedule.versions || []).map((version, versionIndex) => (
                            <linearGradient
                              key={version.id}
                              id={version.id}
                              x1="50%"
                              y1="0%"
                              x2="50%"
                              y2="100%"
                            >
                              <stop
                                offset="0%"
                                stopColor={gradientColor}
                                stopOpacity={0.5}
                              />
                              <stop
                                offset="100%"
                                stopColor={gradientColor}
                                stopOpacity={0}
                              />
                            </linearGradient>
                          ))}
                        </defs>
                      </LineChart>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>

          {/* Add spacing between the charts and audit log */}
          <Box sx={{ my: 4 }}>
            <Divider />
          </Box>

          {/* Audit Log Section - directly using AuditLogGrid */}
          {scheduleId && (
            <Card variant="outlined">
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                  <Typography variant="h6">Schedule Event Log</Typography>
                  <Button
                    startIcon={<RefreshIcon />}
                    size="small"
                    onClick={handleRefresh}
                  >
                    Refresh
                  </Button>
                </Box>

                <Box sx={{ mb: 3 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                      <DatePicker
                        label="Start Date"
                        value={dateRange[0]}
                        onChange={(newValue) => setDateRange([newValue, dateRange[1]])}
                        slotProps={{
                          textField: {
                            size: "small",
                            sx: { width: 160 }
                          }
                        }}
                      />
                      <DatePicker
                        label="End Date"
                        value={dateRange[1]}
                        onChange={(newValue) => setDateRange([dateRange[0], newValue])}
                        slotProps={{
                          textField: {
                            size: "small",
                            sx: { width: 160 }
                          }
                        }}
                        minDate={dateRange[0]}
                      />

                      <FormControl size="small" sx={{ width: 180 }}>
                        <InputLabel id="event-type-label">Event Type</InputLabel>
                        <Select
                          labelId="event-type-label"
                          value={eventType}
                          label="Event Type"
                          onChange={(e) => setEventType(e.target.value)}
                        >
                          <MenuItem value="">All Events</MenuItem>
                          {uniqueEventTypes.map((type) => (
                            <MenuItem key={type} value={type}>{type}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControl size="small" sx={{ width: 100 }}>
                        <InputLabel id="limit-select-label">Limit</InputLabel>
                        <Select
                          labelId="limit-select-label"
                          value={limit}
                          label="Limit"
                          onChange={(e) => setLimit(Number(e.target.value))}
                        >
                          <MenuItem value={10}>10</MenuItem>
                          <MenuItem value={25}>25</MenuItem>
                          <MenuItem value={50}>50</MenuItem>
                          <MenuItem value={100}>100</MenuItem>
                        </Select>
                      </FormControl>

                      <Button
                        variant="outlined"
                        size="small"
                        onClick={handleClearFilters}
                        disabled={!dateRange[0] && !dateRange[1] && !eventType}
                      >
                        Clear
                      </Button>
                    </Stack>
                  </LocalizationProvider>
                </Box>

                {auditError && (
                  <Box sx={{ mb: 2, p: 2, bgcolor: '#ffebee', borderRadius: 1 }}>
                    <Typography color="error">{auditError}</Typography>
                  </Box>
                )}

                {/* Using the key to force a refresh when needed */}
                <AuditLogGrid
                  key={refreshKey}
                  ref={auditLogRef}
                  initialParams={{
                    schedule_uid: scheduleId,
                    limit,
                    order: 'newest-first'
                  }}
                  dateRange={dateRange}
                  eventType={eventType}
                  onError={handleAuditError}
                  hideScheduleUidColumn={true} // Hide the schedule UID column since we're already in a schedule context
                  minHeight={400}
                />
              </CardContent>
            </Card>
          )}
        </>
      )}
    </Page>
  );
};

export default ScheduleDetailsPage;
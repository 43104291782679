import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import { Slide } from '@mui/material';

interface SlidingPanelProps {
  open: boolean;
  onClose: () => void;
  title: string;
  data: any;
}

const SlidingPanel: React.FC<SlidingPanelProps> = ({ open, onClose, title, data }) => {
  return (
    <Slide direction="left" in={open} mountOnEnter unmountOnExit>
      <Paper
        elevation={6}
        square
        sx={{
          position: 'fixed',
          top: 0,
          right: 0,
          width: '600px',
          height: '100vh',
          zIndex: 1300,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          borderRadius: 0, // Ensures no rounded corners
          boxShadow: '-4px 0 10px rgba(0, 0, 0, 0.1)'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            p: 2,
            bgcolor: 'background.paper',
            borderBottom: '1px solid',
            borderColor: 'divider'
          }}
        >
          <Typography>{title}</Typography>
          <IconButton onClick={onClose} edge="end" aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ p: 2, overflowY: 'auto', flexGrow: 1 }}>
          {data && Object.entries(data).map(([key, value]) => (
            <Box key={key} sx={{ mb: 2 }}>
              <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                {key}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  wordBreak: 'break-word',
                  fontFamily: typeof value === 'object' ? '"Roboto Mono", monospace' : 'inherit',
                  whiteSpace: typeof value === 'object' ? 'pre-wrap' : 'normal'
                }}
              >
                {typeof value === 'object'
                  ? JSON.stringify(value, null, 2)
                  : String(value)
                }
              </Typography>
              <Divider sx={{ mt: 2 }} />
            </Box>
          ))}
          {!data && (
            <Typography>No data available</Typography>
          )}
        </Box>
      </Paper>
    </Slide>
  );
};

export default SlidingPanel;
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { SparkLineChart } from '@mui/x-charts/SparkLineChart';
import InfoIcon from '@mui/icons-material/Info';
import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import Page from '../../components/Page';
import CellWithPopover from '../../components/Popover';
import StatusChip from '../../components/StatusChip';
import HtmlTooltip from '../../components/HtmlTooltip';
import StatusTooltipContent from '../../components/tooltips/StatusTooltipContent';
import { apiClient } from '../../apiClient';

interface Schedule {
  date: string;
  uid: string;
  created_ts: string;
  org_uid: string;
  market: string;
  last_modified_ts: string;
  tso: string;
  latest_version: number;
  status: string;
  sender_id?: string;
  production: { ts: number[]; amount: number[] } | { ts: never[]; amount: string[] };
  consumption: { ts: number[]; amount: number[] } | { ts: never[]; amount: string[] };
  internal: { ts: number[]; amount: number[] } | { ts: never[]; amount: string[] };
  external: { ts: number[]; amount: number[] } | { ts: never[]; amount: string[] };
}

const paginationModel = { page: 0, pageSize: 5 };

const columns: GridColDef[] = [
  {
    field: 'date',
    headerName: 'Date',
    flex: 1,
    maxWidth: 100,
  },
  {
    field: 'uid',
    headerName: 'UID',
    flex: 0,
    minWidth: 50,
    renderCell: (params) => (
      <Link to={`/schedule-management/debugger/${params.value}`} style={{ textDecoration: 'none', color: '#1976d2' }}>
        <CellWithPopover value={params.value} displayValue={`...${params.value.slice(-8)}`} />
      </Link>
    )
  },
  {
    field: 'tso',
    headerName: 'TSO',
    flex: 1,
    width: 50,
    renderCell: (params) => <CellWithPopover value={params.value} displayValue={params.value} />,
  },
  { field: 'sender_id', headerName: 'BRP', flex: 1, minWidth: 150 },
  { field: 'latest_version', headerName: 'Version', flex: 1, minWidth: 30, maxWidth: 70 },
  {
    field: 'production',
    headerName: 'Production',
    flex: 1,
    minWidth: 100,
    renderCell: (params) => renderSparkline(params.value as { amount: number[]; ts: number[] }, 'production'),
  },
  {
    field: 'consumption',
    headerName: 'Consumption',
    flex: 1,
    minWidth: 100,
    renderCell: (params) => renderSparkline(params.value as { amount: number[]; ts: number[] }, 'consumption'),
  },
  {
    field: 'internal',
    headerName: 'Internal',
    flex: 1,
    minWidth: 100,
    renderCell: (params) => renderSparkline(params.value as { amount: number[]; ts: number[] }, 'internal'),
  },
  {
    field: 'external',
    headerName: 'External',
    flex: 1,
    minWidth: 100,
    renderCell: (params) => renderSparkline(params.value as { amount: number[]; ts: number[] }, 'external'),
  },
  {
    field: 'status',
    renderHeader: () => (
      <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
        <span>Status</span>
        <HtmlTooltip title={<StatusTooltipContent />}>
          <InfoIcon fontSize="small" style={{ cursor: 'pointer', color: '#1976d2' }} />
        </HtmlTooltip>
      </div>
    ),
    minWidth: 180,
    flex: 1,
    renderCell: (params) => <StatusChip status={params.value as 'accepted' | 'submitted' | 'pending' | 'partially_accepted' | 'rejected'} />,
  },
];

const CustomNoRowsOverlay = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        gap: 1, // Add some spacing between icon and text
      }}
    >
      <OfflineBoltIcon sx={{ fontSize: 40 }} />
      <Typography>No schedules found.</Typography>
    </Box>
  );
};

const renderSparkline = (
  data: { amount: number[]; ts: number[] },
  type: 'production' | 'consumption' | 'internal' | 'external'
) => {
  if (!data || data.amount.length <= 1) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <Chip label="N/A" color="default" size="small" />
      </Box>
    );
  }

  const colorMap = {
    production: 'hsl(120, 61%, 50%)', // Blue
    consumption: 'hsl(30, 100%, 50%)',  // Red
    internal: 'hsl(200, 70%, 50%)',   // Green
    external: 'hsl(280, 60%, 50%)',    // Orange
  };

  return (
    <SparkLineChart
      data={data.amount.map(Number)}
      width={100}
      height={32}
      plotType="line"
      showHighlight
      showTooltip
      colors={[colorMap[type]]}
      xAxis={{
        scaleType: 'band',
        data: data.ts,
        valueFormatter: (value) => `Quarter: ${value}`, // Format x-axis values
      }}
    />
  );
};

const ScheduleManagementPage = () => {
  const [schedules, setSchedules] = useState<Schedule[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [dateRange, setDateRange] = useState<[any, any]>([null, null]);
  const [filteredRows, setFilteredRows] = useState<any[]>([]);

  useEffect(() => {
    setLoading(true);
    apiClient('schedules')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch schedules');
        }
        return response.json();
      })
      .then((data: Schedule[]) => {
        const schedulesWithData = data.map(async (schedule) => {
          try {
            const versionResponse = await apiClient(`schedules/${schedule.uid}/versions/${schedule.latest_version}`);
            if (!versionResponse.ok) {
              throw new Error('Failed to fetch schedule version');
            }
            const versionData = await versionResponse.json();
            // Map and process version data
            const series = versionData?.spec?.series || [];
            const senderId = versionData?.spec?.sender_id || 'N/A';

            const typeMap: { [key: string]: { amount: number[]; ts: number[] } } = {
              production: { amount: [], ts: [] },
              consumption: { amount: [], ts: [] },
              internal: { amount: [], ts: [] },
              external: { amount: [], ts: [] },
            };

            series.forEach((item: { type: string; data: { amount: number; ts: number }[] }) => {
              item.data.forEach((dataPoint: { amount: number; ts: number }) => {
                if (typeMap[item.type]) {
                  typeMap[item.type].amount.push(dataPoint.amount);
                  typeMap[item.type].ts.push(dataPoint.ts);
                }
              });
            });

            return {
              ...schedule,
              production: typeMap.production,
              consumption: typeMap.consumption,
              internal: typeMap.internal,
              external: typeMap.external,
              status: versionData.status || 'Unknown',
              sender_id: senderId,
            };
          } catch (error) {
            console.error(error);
            return { ...schedule, status: 'Error', sender_id: 'Error' };
          }
        });

        Promise.all(schedulesWithData)
          .then((processedSchedules) => {
            const mappedRows = processedSchedules.map((schedule, index) => ({
              id: index,
              date: schedule.date,
              uid: schedule.uid,
              market: schedule.market,
              tso: schedule.tso,
              latest_version: schedule.latest_version,
              status: schedule.status || 'Unknown',
              sender_id: schedule.sender_id || 'N/A',
              production: schedule.production,
              consumption: schedule.consumption,
              internal: schedule.internal,
              external: schedule.external,
            }));
            setRows(mappedRows);
          })
          .catch((err) => console.error(err))
          .finally(() => setLoading(false)); // Stop loading
      })
      .catch((error) => {
        console.error(error);
        setError('Failed to load schedules');
        setLoading(false); // Stop loading on error
      });
  }, []);

  useEffect(() => {
    if (schedules.length > 0) {
      const mappedRows = schedules.map((schedule, index) => ({
        id: index,
        date: schedule.date,
        uid: schedule.uid,
        market: schedule.market,
        tso: schedule.tso,
        latest_version: schedule.latest_version,
        status: schedule.status || 'Loading...',
        sender_id: schedule.sender_id || 'N/A',
        production: schedule.production,
        consumption: schedule.consumption,
        internal: schedule.internal,
        external: schedule.external,
      }));
      setRows(mappedRows);
    }
  }, [schedules]);

  useEffect(() => {
    const [startDate, endDate] = dateRange;
    if (startDate && endDate) {
      const filtered = rows.filter((row) => {
        const rowDate = dayjs(row.date);
        return rowDate.isAfter(startDate, 'day') || rowDate.isSame(startDate, 'day') &&
               (rowDate.isBefore(endDate, 'day') || rowDate.isSame(endDate, 'day'));
      });
      setFilteredRows(filtered);
    } else {
      setFilteredRows(rows);
    }
  }, [dateRange, rows]);

  const handleClearDateRange = () => {
    setDateRange([null, null]);
  };

  return (
    <Page>
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Debugger
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <DatePicker
              label="Start Date"
              value={dateRange[0]}
              onChange={(newValue) => setDateRange([newValue, dateRange[1]])}
              slotProps={{
                textField: {
                  size: "small",
                  sx: { width: 200 }
                }
              }}
            />
            <DatePicker
              label="End Date"
              value={dateRange[1]}
              onChange={(newValue) => setDateRange([dateRange[0], newValue])}
              slotProps={{
                textField: {
                  size: "small",
                  sx: { width: 200 }
                }
              }}
              minDate={dateRange[0]}
            />
          </Box>
          <Button
            variant="outlined"
            size="small"
            onClick={handleClearDateRange}
            disabled={!dateRange[0] && !dateRange[1]}
          >
            Clear Filter
          </Button>
        </Stack>
      </LocalizationProvider>

      <Box style={{ display: 'flex', flexDirection: 'column', minHeight: loading ? 320 : 'auto' }}>
        <DataGrid
          rows={filteredRows}
          columns={columns}
          loading={loading}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          slotProps={{
            loadingOverlay: {
              variant: 'skeleton',
              noRowsVariant: 'skeleton',
            },
          }}
          density="compact"
          pageSizeOptions={[10, 20, 50]}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
                page: 0
              }
            },
            sorting: {
              sortModel: [{ field: 'date', sort: 'desc' }],
            },
          }}
          pagination
        />
      </Box>
    </Page>
  );
};

export default ScheduleManagementPage;

import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Page from '../components/Page';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import { apiClient } from '../apiClient';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import DeleteApiKeyDialog from './DeleteApiKeyDialog';

interface APIClient {
  uid: string;
  client_id: string;
  org_uid: string;
  client_secret?: string;
}

const ApiKeysPage = () => {
  const [apiClients, setApiClients] = useState<APIClient[]>([]);
  const [selectedClientId, setSelectedClientId] = useState<string | null>(null);
  const [rows, setRows] = useState<APIClient[]>([]);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [clientDetails, setClientDetails] = useState<{
    clientId: string;
    clientSecret: string;
  }>({ clientId: '', clientSecret: '' });

  const columns: GridColDef[] = [
    {
      field: 'client_id',
      headerName: 'Client ID',
      flex: 1.5,
      minWidth: 200,
      renderCell: (params: GridRenderCellParams) => (
        <Box sx={{ pl: 2 }}>
          {params.value}
        </Box>
      )
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.3,
      minWidth: 150,
      renderCell: (params) => (
        <>
      <Button
        variant="outlined"
        size="small"
        color="secondary"
        startIcon={<DeleteIcon />}
        onClick={() => {
          setSelectedClientId(params.row.client_id);  // Store the clicked client_id
          setOpenConfirmation(true);  // Open the confirmation dialog
        }}
      >
        Remove
      </Button>
      <DeleteApiKeyDialog
        open={openConfirmation}
        onClose={() => setOpenConfirmation(false)}
        onDelete={() => handleDeleteClick(selectedClientId)}  // Pass the selected client_id
        apiKeyName={selectedClientId}  // Use the selected client_id in the dialog
      />
    </>
      ),
    },
  ];

  useEffect(() => {
    const fetchApiClients = async () => {
      try {
        const response = await apiClient('api-clients');
        if (!response.ok) {
          throw new Error('Failed to fetch API clients');
        }
        const data: APIClient[] = await response.json();
        setApiClients(data);
      } catch (error) {
        console.error('Error fetching API clients:', error);
      }
    };

    fetchApiClients();
  }, []);

  useEffect(() => {
    const updatedRows = apiClients.map((client, index) => ({
      id: client.uid || index,
      ...client,
    }));
    setRows(updatedRows);
  }, [apiClients]);

  // Function to handle API client creation
  const handleCreateApiClient = async () => {
    try {
      setLoading(true);
      const response = await apiClient('api-clients', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
      });

      if (!response.ok) {
        throw new Error('Failed to create API client');
      }

      const newClient: APIClient = await response.json();
      setApiClients((prevClients) => [...prevClients, newClient]);

      setClientDetails({
        clientId: newClient.client_id,
        clientSecret: newClient.client_secret || 'No client secret available',
      });
      setOpenModal(true);
    } catch (error) {
      console.error('Error creating API client:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false); // Close the modal
  };

  const handleDeleteClick = async (client_id: string | null) => {
    if(client_id){
      try {
        const response = await apiClient(`api-clients/${client_id}`, {
          method: 'DELETE',
        });
  
        if (!response.ok) {
          throw new Error('Failed to delete API client');
        }
  
        // Remove the deleted client from the local state
        setApiClients((prevClients) =>
          prevClients.filter((client) => client.client_id !== client_id)
        );
  
        setOpenConfirmation(false);
      } catch (error) {
        console.error('Error deleting API client:', error);
      }
    }
  };



  return (
    <Page>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          mb: 2,
        }}
      >
        <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
          API Keys
        </Typography>
        <Button
          variant="contained"
          color="success"
          size="small"
          startIcon={<AddIcon />}
          onClick={handleCreateApiClient}
          disabled={loading}
        >
          Create Client
        </Button>
      </Box>
      <DataGrid
        
        columns={columns}
        rows={rows}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }
        initialState={{
          pagination: { paginationModel: { pageSize: 20 } },
        }}
        pageSizeOptions={[10, 20, 50]}
        disableColumnResize
      />


      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-title" variant="h3" component="h2">
            Client Secret Created
          </Typography>
          <Typography id="modal-description" variant="body1" sx={{ mt: 2 }}>
            This is the only time you will see the client secret. Please store it thoughtfully and securely.
          </Typography>
          <Typography id="modal-description" variant="body1" sx={{ mt: 2 }}>
            Client ID: {clientDetails.clientId}
          </Typography>
          <Box
            sx={{
              padding: '16px',
              marginTop: '16px',
              borderRadius: '8px',
              border: '1px solid #ddd',
              fontSize: '14px',
              overflowY: 'auto',
            }}
          >
            {clientDetails.clientSecret}
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCloseModal}
            sx={{ mt: 3 }}
          >
            Close
          </Button>
        </Box>
      </Modal>

    </Page>
  );
};

export default ApiKeysPage;
